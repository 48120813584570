import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { RootPageLayout, Disclosures } from '../components'

import SMAIcon from '../images/sma_icon.svg'
import VITIcon from '../images/vit_icon.svg'

const IndexPage = () => {
  if (process.env.GATSBY_CAPGROUP_FUNDS !== 'true')
    return null;

  return <RootPageLayout id="home" className="page-index" showNav={process.env.GATSBY_CLIENT === 'milliman'} hideContact={true} replaceFooter={<Disclosures style={{marginTop: '-3rem'}} />}>

    <div className="header-image"></div>

    <Container className="lead">
      <Row className="justify-content-center">
        <Col xs={10}>
          <h1>
            Milliman Investment Funds
          </h1>
          <p className="mb-0">
            Milliman leverages diverse expertise in actuarial science, healthcare, and finance to craft a 
            wide array of investment options. These products are designed to meet the unique needs of various
            clients, including pension funds, insurance companies, and individual investors. By combining deep
            industry knowledge with cutting-edge financial modeling, Milliman delivers tailored solutions that
            aim to optimize returns and manage risk effectively.
          </p>
        </Col>
      </Row>
    </Container>
  
    <div className="series">
      <Container>
        <h2 className="text-white text-center mb-4" style={{fontWeight: 500}}>Explore products by type:</h2>
        <Row className="justify-content-center align-items-stretch">
          <Col xs={10} lg={6} xl={5} className="my-3 justify-content-stretch">
            <a className="product-card-link" href={process.env.GATSBY_SMA_URL}>
              <Card className="h-100 text-center">
                <Card.Body>
                  <img className="mb-4" src={SMAIcon} alt="" style={{height: '6rem'}} />
                  <h4 className="mt-2 mb-3">Separately Managed Accounts (SMAs)</h4>
                  Healthcare Investment Solutions
                </Card.Body>
              </Card>
            </a>
          </Col>

          <Col xs={10} lg={6} xl={5} className="my-3 justify-content-stretch">
            <Link className="product-card-link" to="/capgroup/overview">
              <Card className="h-100 text-center">
                <Card.Body>
                  <img className="mb-3" src={VITIcon} alt="" style={{height: '6rem'}} />
                  <h4 className="mt-2 mb-3">Variable Insurance Trusts (VITs)</h4>
                  Structured Outcome Funds
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>

    <Container>
      <Row className="justify-content-center">
        <Col xs={10} className="mt-5 mb-3">
          <h5 className="fw-bold mb-4">About Milliman Financial Risk Management LLC</h5>
          <div>
              Milliman Financial Risk Management LLC is a global leader in financial risk management to the retirement savings industry.
              Established in 1998, the practice includes over 200 professionals operating from three trading platforms around the world (Chicago, London, and Sydney).
              Milliman FRM is a subsidiary of Milliman, Inc.
          </div>
        </Col>
      </Row>
    </Container>

  </RootPageLayout>
}

export default IndexPage
